import React, { useEffect, useState } from "react";
import "./Claim.css";
import GetFloatValue from "../../Common/GetFloatValue";
import Loader from "../../Components/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Data } from "../../Common/Data";
import ContractDetails from "../../Contracts/ContractDetails";
import { ethers } from "ethers";
import GetMined from "../../Common/GetMined";
import GetClaimed from "../../Common/GetClaimed";
import ChainId from "../../Common/ChainId";
import getAddress from "../../Common/GetAddress";
import axios from "axios";
import { ApiPaths } from "../../Config";

const UsdtClaim = () => {
  const [walletAddress, setWalletAddress] = useState("");
  const [tokenMined, setTokenMined] = useState("0.0");
  const [tokenClaimed, setTokenClaimed] = useState("0.0");
  const [tokenPending, setTokenPending] = useState("0.0");
  const [loading, setLoading] = useState(false);
  const [claimStatus, setClaimStatus] = useState(false);

  useEffect(() => {
    let jsondata = localStorage.getItem("dashboardData");
    const data = JSON.parse(jsondata);
    if (data) {
      setWalletAddress(data?.profile?.[0]?.wallet_address);
      fetchContractData(data?.profile?.[0]?.wallet_address);
    } else {
      alert("Address not found");
    }
    FetchPlanData();
  }, []);

  const toastSuccess = (msg) => toast.success(msg);
  const toastFailed = (msg) => toast.error(msg);

  async function fetchContractData(address) {
    try {
      setLoading(true);
      if (!address) throw new Error("Invalid address");

      let tokenMined = await GetMined(address);
      let tokenClaimed = await GetClaimed(address);
      if (!tokenMined || !tokenClaimed)
        throw new Error("Failed to fetch token data");

      let pending = tokenMined - tokenClaimed;
      console.log("tokenMined", tokenMined);
      setTokenMined(parseFloat(tokenMined).toFixed(2));
      setTokenClaimed(parseFloat(tokenClaimed).toFixed(2));

      if (tokenClaimed <= tokenMined) {
        setTokenPending(0.0);
      } else {
        setTokenPending(GetFloatValue(pending));
      }
    } catch (e) {
      console.error("Error fetching contract data:", e);
      setTokenMined("0.0");
      setTokenClaimed("0.0");
      setTokenPending("0.0");
    } finally {
      setLoading(false);
    }
  }

  async function claim() {
    setLoading(true);
    try {
      let chain = ChainId();
      if (!chain) throw new Error("Chain not found");

      const connectedAddress = await getAddress();
      if (!connectedAddress || !walletAddress)
        throw new Error("Wallet address not connected properly");

      const { ethereum } = window;
      if (ethereum && connectedAddress === walletAddress) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const busdInstance = new ethers.Contract(
          ContractDetails.contract_usdt,
          ContractDetails.contractABI_usdt,
          signer
        );
        let inc = await busdInstance.claim({
          value: ethers.utils.parseEther("0"),
        });
        await inc.wait();

        fetchContractData(walletAddress);
        toastSuccess("Claimed Successfully");
      } else {
        toastFailed(
          "Please connect with the registered wallet address: " + walletAddress
        );
      }
    } catch (error) {
      console.error("Claim transaction failed:", error);
      toastFailed("Transaction failed");
    } finally {
      setLoading(false);
    }
  }

  function FetchPlanData() {
    setLoading(true);
    let token = localStorage.getItem("token");

    axios({
      method: "get",
      url: ApiPaths.clainStatus,
      headers: {
        "Content-Type": "multipart/form-data",
        token: token,
      },
    })
      .then(function (response) {
        Data.isDebug && console.log("plan data", response);
        if (response?.data?.res == 0) {
          setClaimStatus(true);
        }
        setLoading(false);
      })
      .catch(function (response) {
        console.error(response);
        setLoading(false);
      });
  }

  return (
    <>
      <section className="dashboard">
        {loading && <Loader />}
        <ToastContainer autoClose={1500} />
        <h1 className="textHeadingWithMargin">USDT Staking</h1>
        <div className="claimDiv">
          <div>
            <h1>{parseFloat(tokenMined / 1e18).toFixed(4)}</h1>
            <p>Coin Mined</p>
          </div>
          <div>
            <h1>{parseFloat(tokenClaimed / 1e18).toFixed(4)}</h1>
            <p>Total Claimed</p>
          </div>
          <div>
            <h1>{parseFloat(tokenPending / 1e18).toFixed(4)}</h1>
            <p>Pending</p>
          </div>
          <div>
            {claimStatus ? (
              <button onClick={() => claim()}>Claim</button>
            ) : (
              <button disabled style={{ filter: "grayScale(1)" }}>
                Claim
              </button>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default UsdtClaim;

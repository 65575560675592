import React, { useEffect, useState } from "react";
import "./AllCapping.css";
import TimeToDate from "../../Common/TimeToData";
import Loader from "../../Components/Loader/Loader";
import UsdtStakes from "../../Common/UsdtStake";
import UsdtAllMined from "../../Common/UsdtAllMined";
import UsdtAllClaimed from "../../Common/UsdtAllClaimed";
import UsdtDetailedChart from "../../Components/DetailedChart/UsdtDetailedChart";
const UsdtCapping = () => {
  const [walletAddress, setWalletAddress] = useState(
    localStorage.getItem("walletAddress")
  );
  const [loading, setLoading] = useState();
  const [allStakingData, setAllStakingData] = useState();

  var x = 0;
  useEffect(() => {
    if (x === 0) {
      FetchData();
      x = 1;
    }
  }, []);

  async function FetchData() {
    setLoading(true);
    let tempArray = [];
    try {
      let stakingData = await UsdtStakes(walletAddress);

      let allMined = await UsdtAllMined(walletAddress);

      let allClaimed = await UsdtAllClaimed(walletAddress);

      for (let i = 0; i < stakingData.length; i++) {
        let tempStake = parseFloat(stakingData?.[i]?.stakedUSDT) / 1e18;
        let tempDays = String(stakingData?.[i]?.duration);
        let tempLastClaim = TimeToDate(
          String(stakingData?.[i]?.lastClaimedTime)
        );
        let tempStartTime = TimeToDate(String(stakingData?.[i]?.startTime));
        let tempEndTime = TimeToDate(String(stakingData?.[i]?.endTime));
        let tempBonus = parseFloat(stakingData?.[i]?.bonus) / 1e18;
        let tempPaid = parseFloat(stakingData?.[i]?.paid) / 1e18;
        let tempAllMined = parseFloat(allMined?.[i]) / 1e18;
        let tempAllClaimed = parseFloat(allClaimed?.[i]) / 1e18;
        let allData = {
          stake: tempStake,
          days: tempDays,
          lastClaim: tempLastClaim,
          bonus: tempBonus,
          startTime: tempStartTime,
          paid: tempPaid,
          purchase: tempAllMined - tempAllClaimed,
          endTime: tempEndTime,
          allMined: tempAllMined,
          allClaimed: tempAllClaimed,
        };
        tempArray.push(allData);
      }
      console.log(tempArray);
      setAllStakingData(tempArray);
      setLoading(false);
    } catch (e) {
      console.log("eeeeeeeeeeeeeeee", e);
      setLoading(false);
    }
  }
  return (
    <>
      {loading && <Loader />}
      <section className="dashboard">
        {allStakingData?.map((x, i) => {
          return <UsdtDetailedChart data={x} />;
        })}
      </section>
    </>
  );
};

export default UsdtCapping;
